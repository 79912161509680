import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate } from "react-router-dom";
import Header from './Header.js'
import { useEffect, useState } from 'react';
import { API_DOMAIN } from './const';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PostDataToApi, checkLogin } from './Utils'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Attendance(props) {
  const attendanceList = useSelector((state) => state.appstate.attendanceList)
  const location = useSelector((state) => state.appstate.location);
  const role = useSelector((state) => state.appstate.role);
  const [attendanceObject, setAttendanceObject] = useState([]);
  const [ empid, setEmpid] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let month = (new Date()).getMonth();
  let year = (new Date()).getFullYear();

  const getAttendance = () =>{
    let temp = attendanceList.filter(i => i.empid == empid);
    if(temp.length > 0){
      setAttendanceObject(temp[0]);
      setShowDetails(true);
    }
    
  }
  const fnChange = (key, val) =>{
    let temp = attendanceObject;
    temp[key] = val;
    setAttendanceObject(temp);
  } 
  /*
  const fnChange = (val, key, id)=> {
    let t  = attendanceList;
    console.log("test")
    t.map(i => {
      if(i.empid == id){
        i.month = month;
        i.year = year;
        i.changed = true;
        if(key == "workinghours1")
         if(i.workinghours != val)
         alert("pl verify working hours");
        i.workinghours = val;     
      }
    });

    dispatch(actions.setAttendanceList(t));
  }
*/
  const handleView = (event) => {
    dispatch(actions.selectEmployee(event.target.value));
  }

  useEffect(() => {
    checkLogin(navigate);
    });

  useEffect(() => {
  /*  if(empid != -1){
      let arr = Employees.filter(i => i.empid === empid);
//      setEmployee(arr[0]);
if(arr && arr.length>0 )
dispatch(actions.setEmployee(arr[0]));  
}*/
LoadData();

  }, []);

  const LoadData = () => {
    let obj = {"location" : location, "month" : month, "year" : year, "role" : role};
PostDataToApi( API_DOMAIN + "employee/getAttendanceList", obj).then((res) =>{
  console.log(res.data);
  if(typeof res.data === "object")
  dispatch(actions.setAttendanceList(res.data));
});

  }
  const fnCreateEmployee = () => {

    let obj = [];
    attendanceObject.month = month;
    attendanceObject.year = year;
    obj.push(attendanceObject);
    PostDataToApi(API_DOMAIN + "employee/createAttendance", obj).then((res) =>{
    console.log(res.data);
    if(res.data && res.data[0]["LAST_INSERT_ID()"]){
      dispatch(actions.setAttendanceList([{}]));
      //LoadData();
      navigate("/ListEmployee")        
    }
      
    });
  }

  return (
    <div className="container">
    <Header/>
  <div className='title'>
     Attendance
    </div>
    <div>
     <form >			
     <div className="form-row">
    <div className="form-group col-md-6">
        <label for="name"><font color="#0c4c8a">Empid:</font></label>
        <input type="text" className="form-control" value={empid} onBlur={() => getAttendance()} onChange={(e) => setEmpid(e.target.value)} />
    </div>
    
  </div>

  { showDetails && 
  <>
  <div className="form-row">
    <div className="form-group col-md-6">
        <label for="name"><font color="#0c4c8a">Name</font></label>
        <input type="text" className="form-control" readonly value={attendanceObject.name} />
    </div>
    
    <div  className="form-group col-md-6">
      <label for="adhar"><font color="#0c4c8a">Work</font></label> 
      <input type="text" className="form-control" value={attendanceObject.work} readonly />      
    </div>      
  </div>
  
  
  <div className="form-row">
    <div className="form-group col-md-6">
    <label for="age"><font color="#0c4c8a">Designation</font></label>
    <input  className="form-control"  value={attendanceObject.work} readonly />
    </div>
    
    <div className="form-group col-md-6">
    <label for="Aadhar"><font color="#0c4c8a">Location</font></label>
    <input type="number" className="form-control" value={attendanceObject} />
    </div>      
  </div>
  
  
  <div className="form-row">
  <div className="form-group col-md-6">
  <label for="edq"><font color="#0c4c8a">workinghours</font></label>
  <input type="number" className="form-control" value={attendanceObject.workinghours} onChange={e => fnChange("workinghours", e.target.value)} />
  </div>
  { attendanceObject.work === "part" && 
  <div className="form-group col-md-6">
  <label for="addr"><font color="#0c4c8a">additional days</font></label>
  <input type="number" className="form-control" value={attendanceObject.weekendhours} onChange={e => fnChange("weekendhours", e.target.value)} />
  </div>
  }
  
  </div>
  
  <div className="form-row">
  <div className="form-group col-md-6">
  <input type="button"  class="btn btn-success btn-large" value="Submit" onClick={() => fnCreateEmployee()}/>
  </div>
  </div>          
  </>}

  

<div className="form-row">
  <div className="form-group col-md-12">      
    </div>
  </div>

    </form>
    </div>

</div>

    /*
    <div className="container">
        <Header/>
      <div className='title'>
Attendance        </div>

<TableContainer component={Paper} sx={{backgroundColor: 'black' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Empid</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Work</StyledTableCell>
            <StyledTableCell align="right">Designation</StyledTableCell>
            <StyledTableCell align="right">Location</StyledTableCell>
            <StyledTableCell align="right">No of days/Hours</StyledTableCell>
            <StyledTableCell align="right">ReEnter No of days/Hours</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor: 'white' }}>
          {attendanceList && attendanceList.length > 0 && attendanceList.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.empid}
              </StyledTableCell>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.work}</StyledTableCell>
              <StyledTableCell align="right">{row.designation}</StyledTableCell>
              <StyledTableCell align="right">{row.location}</StyledTableCell>
              <StyledTableCell align="right">
              <TextField 
          id="standard-error-helper-text"
          label="Working Hours"
          variant="standard" onChange={(e) => fnChange(e.target.value, "workinghours", row.empid)}></TextField>
              </StyledTableCell>
              <StyledTableCell align="right">
              <TextField 
          id="standard-error-helper-text"
          label="Working Hours"
          variant="standard" onBlur={(e) => fnChange(e.target.value, "workinghours1", row.empid)}></TextField>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
{attendanceList && attendanceList.length > 0 ? 
            <div className="form-group col-md-2">
              <input type="button"  class="btn btn-success btn-large" value="Submit" onClick={() => fnCreateEmployee()}/>
            </div>
            :     
              <div>No attendance for this month</div>
            
}
    </div>
*/

  );
}




