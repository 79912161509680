import axios from 'axios';


export const checkLogin = (navigate) =>{
  console.log("checklogin" + localStorage.getItem("LoggedIn") + " " + localStorage.getItem("LoggedIn"))
  if(localStorage.getItem("LoggedIn") !== "true"){    
    console.log("checklogin inside" + localStorage.getItem("LoggedIn"))  
    navigate("/");
  }
}
export const PostDataToApi = (apiUrl, postData={}) => {
    https://sandbox.braintreegateway.com/merchants/kzrt44jbftd9bd34/customers
  try{
    return axios
      .post(apiUrl, JSON.stringify(postData), { headers: {
        'content-type': 'application/json'}
    });
  }
  catch(e){
    console.log(e);
  }
/*
      return axios({
        method: 'POST',
        url: apiUrl,
        headers: {
            'content-type': 'application/json'
        },
        data: postData
    })
    */
};
  
export const getDataFromApi = (apiUrl) => {
    return axios.get(apiUrl);
};

export const fileUpload = async(apiurl, formData) => {
  return  await axios.post(apiurl, formData,{
    headers: {
        'content-type': 'multipart/form-data'
    }
});

} 
export const displayValue = (val) => {
  if(!val)
    val = "  ";
    return val;
}