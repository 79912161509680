import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import { PostDataToApi, fileUpload, checkLogin } from './Utils'
import * as React from 'react';
import {useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useTabsList } from '@mui/material';
import { API_DOMAIN, API_DOMAIN_UPLOADS } from './const';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import DatePicker from '@mui/lab/DatePicker';
import { setMonth } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Advance(props) {
  const Employees = useSelector((state) => state.appstate.Employees);
  const employee = useSelector((state) => state.appstate.employee);
  const empaction = useSelector((state) => state.appstate.empaction);
  const sEmpid = useSelector((state) => state.appstate.empid)
  const UPLOAD_ENDPOINT = API_DOMAIN + 'employee/FileUpload';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    checkLogin(navigate);
    });

  const [advance, setAdvance] = React.useState("");
  const [deduction, setDeduction] = React.useState("");
  const [noofmonths, setnoofmonths] = React.useState("");
  const [empid, setEmpid] = React.useState(0);
  const [advanceList, setAdvanceList] = React.useState([]);
  const [showDetails, setShowDetails] = React.useState(false);
  

  
  let allMonths = [];
  for(let x = 1; x<= 120; x++) {
    allMonths.push(x);
  }

  //const [employee, setEmployee] = React.useState({"empid":"","name":"s","designation":"","dob":"","aadhar_no":"","location":"","address":"","phone_no":"","alternate_no":"","role":"1","isdeleted":0,"work":""});
  const fnChange = (val, key)=> {
    employee[key] = val;
    //setEmployee(employee);
    dispatch(actions.setEmployee(employee));
  }

  const LoadData = () => {
    
    let obj = {"empid" : empid};
    PostDataToApi( API_DOMAIN + "employee/GetAdvanceList", obj).then((res) =>{
      console.log(res.data);
      if(typeof res.data == "object" ){
        setAdvanceList(res.data);
      }
    });
    
  }
 
  const monthList = () => allMonths.map((x) => { 
  //  console.log("ssdd" + x);
    return(<option value={x}>{x}</option>)});

    const fnGetEmployee = (seid) => {
      let arr = null;
      if(empid != -1){
        if(seid)
        arr = Employees.filter(i => i.empid == seid);
        else
        arr = Employees.filter(i => i.empid == empid);
        setDeduction("");
        if(arr && arr.length > 0 ){
          dispatch(actions.setEmployee(arr[0]));
  
          PostDataToApi( API_DOMAIN + "employee/getPendingAdvance", arr[0]).then((res) =>{
            console.log(res.data);
            let obj = arr[0];
            obj = {...obj, ...res.data[0]};
            // setAdvance("");
            // setnoofmonths("");
            setDeduction(obj.deduction);
            if(!obj.advancepending)
              obj.advancepending="";
            if(!obj.remainingmonths)
              obj.remainingmonths = "";
            dispatch(actions.setEmployee(obj));
            setShowDetails(true);
            LoadData();
          });
        }
        else{
          dispatch(actions.setEmployee([]));
        }
      }
    }

    useEffect(() => {
      setEmpid(sEmpid);
      fnGetEmployee(sEmpid);
      /*  
      if(employee.empid){
        setEmpid(employee.empid);
        setDeduction("");  
        PostDataToApi( API_DOMAIN + "employee/getPendingAdvance", employee).then((res) =>{
        console.log(res.data);
        let obj = employee;
        obj = {...obj, ...res.data[0]};
        setDeduction(obj.deduction);
        if(!obj.advancepending)
          obj.advancepending="";
        if(!obj.remainingmonths)
          obj.remainingmonths = "";
        dispatch(actions.setEmployee(obj));
        setShowDetails(true);
        LoadData();
      });
    }
    */
    }, []);
  
  
  const fnCreateEmployee = () => {
    let obj = employee;
    obj.advance = advance;
    obj.doa = new Date();
    obj.noofmonths = noofmonths;
    obj.deduction = deduction;
    setDeduction(deduction);
    PostDataToApi( API_DOMAIN +"employee/CreateAdvance", obj).then((res) =>{
    console.log(res.data);
    if(res.data == true){
        alert("Advance Saved successfuly");
      navigate("/ListEmployee");  
    }
    else
    {
      alert(JSON.stringify(res.data));
    }
      
    });
  }

  return (
    <div className="container">
        <Header/>
      <div className='title'>
         Advance
        </div>
        <div>
         <form >			
         <div className="form-row">
        <div className="form-group col-md-6">
            <label for="name"><font color="#0c4c8a">Empid:</font></label>
            <input type="text" className="form-control" value={empid} onBlur={() => fnGetEmployee()} onChange={(e) => setEmpid(e.target.value)} />
        </div>
        
      </div>

      { showDetails && 
      <>
      <div className="form-row">
        <div className="form-group col-md-6">
            <label for="name"><font color="#0c4c8a">Name</font></label>
            <input type="text" className="form-control" readonly value={employee.name} />
        </div>
        
        <div  className="form-group col-md-6">
          <label for="adhar"><font color="#0c4c8a">Branch</font></label> 
          <input type="text" className="form-control" value={employee.location} readonly />      
        </div>      
      </div>
      
      
      <div className="form-row">
        <div className="form-group col-md-6">
        <label for="age"><font color="#0c4c8a">Work</font></label>
        <input  className="form-control"  value={employee.work} readonly />
        </div>
        
        <div className="form-group col-md-6">
        <label for="Aadhar"><font color="#0c4c8a">Amount</font></label>
        <input type="number" className="form-control" value={advance} onChange={(e) => setAdvance(e.target.value)} placeholder="Enter Advance Amount"/>
        </div>      
      </div>
      
      
      <div className="form-row">
        {/*
      <div className="form-group col-md-6">
      <label for="edq"><font color="#0c4c8a">No of Months</font></label>
      <select  className="form-control" required="" onChange={(e) => setnoofmonths(e.target.value) }>
                <option value="select">select</option>
                {monthList()}
                </select>

      </div>
      */}
      <div className="form-group col-md-6">
      <label for="edq"><font color="#0c4c8a">Balance Amount</font></label>
      <input type="number" className="form-control" value={employee.advancepending} />
      </div>
      </div>
      {/*
      
      <div className="form-row">
      <div className="form-group col-md-12">
      <label for="addr"><font color="#0c4c8a">Remaining Months</font></label>
      <input className="form-control" value={employee.remainingmonths} readonly />
      </div>
      
      
      </div>
      
      <div className="form-row">
      <div className="form-group col-md-6">
      <label for="mob"><font color="#0c4c8a">Deduction Amount</font></label>
      <input type="number" className="form-control"  value={deduction} onChange={(e) => setDeduction(e.target.value)} />
      </div>
      
      </div>      
      */} 
      <div className="form-row">
      <div className="form-group col-md-6">
      <input type="button"  class="btn btn-success btn-large" value="Submit" onClick={() => fnCreateEmployee()}/>
      </div>
      <div className="form-group col-md-6">
      <input type="button"  class="btn btn-success btn-large" value="Close" onClick={() => props.callClose(false)}/>                
      </div>
      </div>          
      </>}

      <div className="form-row">
      <div className="form-group col-md-12 font-weight-bolder">      
        Advance History:
        </div>
      </div>
      <TableContainer >
      <Table  style={{justifyContent:'center',width:'50%'}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>DOA</StyledTableCell>
            <StyledTableCell align="right">Advance</StyledTableCell>
            {/* <StyledTableCell align="right">NoOfMonths</StyledTableCell> */}
            <StyledTableCell align="right">deduction</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor: 'white' }}>
          {advanceList && advanceList.length > 0 && advanceList.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.doa}
              </StyledTableCell>
              <StyledTableCell align="right">{row.advance}</StyledTableCell>
              {/* <StyledTableCell align="right">{row.noofmonths}</StyledTableCell> */}
              <StyledTableCell align="right">{row.deduction}</StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


    <div className="form-row">
      <div className="form-group col-md-12">      
        </div>
      </div>

        </form>
        </div>
    
    </div>
  );
}