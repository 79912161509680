import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import { useEffect } from 'react';
import { API_DOMAIN } from './const';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PostDataToApi,checkLogin } from './Utils'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import MDialog from './MDialog';
import * as React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function EmplNotifications(props) {
    const attendanceList = useSelector((state) => state.appstate.attendanceList)
    const employeenotifylist = useSelector((state) => state.appstate.employeenotifylist);
    const employee = useSelector((state) => state.appstate.employee);
    const empaction = useSelector((state) => state.appstate.empaction);
    const location = useSelector((state) => state.appstate.location);
    const empid = useSelector((state) => state.appstate.empid);
    const role = useSelector((state) => state.appstate.role);
    const dispatch = useDispatch();
    let month = (new Date()).getMonth();
    let year = (new Date()).getFullYear();
    const [showRemarks, setShowRemarks] = React.useState(false);
    const [showEmplRemarks, setShowEmplRemarks] = React.useState(false);
    const [attendance, showAttendance] = React.useState(false);
    const [employees, showEmployee] = React.useState(false);
    const [remarks, setRemarks] = React.useState("");
    const [remarksid, setRemarksid] = React.useState("");
    const [remarkstype, setRemarkstype] = React.useState("");
  
    const navigate = useNavigate();
    useEffect(() => {
      checkLogin(navigate);
      });
  
    const fnChange = (val, id)=> {
      let t  = employeenotifylist;
      console.log("test")
      t.map(i => {
        if(i.empid == id){
          i.payscale = val;        
        }
      });
      dispatch(actions.setEmployeeNotifyList([]));
      dispatch(actions.setEmployeeNotifyList(t));
    }
    
    const setRemarksData = (id, val, type) => {
      setRemarksid(id);
      setRemarks(val);
      setRemarkstype(type);
    }
  
    const updateRemarks = () => {
      if(remarkstype == "employee"){
      let t  = employeenotifylist;
      t.map(i => {
        if(i.empid == remarksid){
          i.remarks = remarks;
        }
      });
      dispatch(actions.setEmployeeNotifyList([]));
      dispatch(actions.setEmployeeNotifyList(t));
      setShowEmplRemarks(false);
      }
      else{
        let t  = attendanceList;
        t.map(i => {
          if(i.attendanceid == remarksid){
            i.remarks = remarks;
          }
        });
        dispatch(actions.setAttendanceList([]));
        dispatch(actions.setAttendanceList(t));
        setShowRemarks(false);  
      }
    }
  
    const fnConfirmed = (attendanceid)=> {
      let t  = attendanceList;
      t.map(i => {
        if(i.attendanceid == attendanceid){
          i.confirmed = i.confirmed == 1 ? 0 : 1;        
        }
      });
      dispatch(actions.setAttendanceList([]));
      dispatch(actions.setAttendanceList(t));
    }
  
      const updateConfirmedinEmpl = (empid) =>{
        let t  = employeenotifylist;
        t.map(i => {
          if(i.empid == empid){
            i.confirmed = i.confirmed == 1 ? 0 : 1;        
          }
        });
        dispatch(actions.setEmployeeNotifyList([]));
        dispatch(actions.setEmployeeNotifyList(t));
      }
    
  
      const updateAdmin = (empid) =>{
        let t  = employeenotifylist;
        t.map(i => {
          if(i.empid == empid){
            i.role = i.role == 1 ? 2 : 1;        
          }
        });
        dispatch(actions.setEmployeeNotifyList([]));
        dispatch(actions.setEmployeeNotifyList(t));
      }
  
  
    useEffect(() => {
      LoadData();
    }, []);
  
  
    const LoadData = () => {
    
      let obj = {"location" : location, "month" : month, "year" : year, "role" : role};
      PostDataToApi( API_DOMAIN + "employee/getNotificationsList", obj).then((res) =>{
        console.log(res.data);
        if(typeof res.data == "object" ){
        
          dispatch(actions.setAttendanceList(res.data.filter(i => i.type == "attendance")));
          dispatch(actions.setEmployeeNotifyList(res.data.filter(i => i.type == "employee")));
        
        }
      });
      
    }
    const fnUpdateAttendance = () => {
      if(attendanceList && attendanceList.length > 0 ){
        PostDataToApi(API_DOMAIN + "employee/updateAttendance", attendanceList).then((res) =>{
        console.log(res.data);
        LoadData();        
        });
      }
  
    }
    const fnCreateEmployee = () => {
  
      
      if(employeenotifylist && employeenotifylist.length > 0 ){
        PostDataToApi(API_DOMAIN + "employee/updateEmployee", employeenotifylist).then((res) =>{
        console.log(res.data);
        LoadData();        
          
        });
      }
      
    }
  
    return (
      <div className="container">
          <Header/>
        <div className='title' style={{cursor:'pointer'}} onClick ={()=> {showAttendance(!attendance);showEmployee(false)}}>Attendance Notification</div>
        
       {attendance && !employees &&
       <>
  
  <TableContainer component={Paper} sx={{backgroundColor: 'red' }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Empid</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">Work</StyledTableCell>
              <StyledTableCell align="right">Designation</StyledTableCell>
              <StyledTableCell align="right">Location</StyledTableCell>
              <StyledTableCell align="right">No of days/Hours</StyledTableCell>
              <StyledTableCell align="right">Approved</StyledTableCell>
              <StyledTableCell align="right">Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: 'white' }}>
            {attendanceList && attendanceList.length > 0 && attendanceList.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.empid}
                </StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">{row.work}</StyledTableCell>
                <StyledTableCell align="right">{row.designation}</StyledTableCell>
                <StyledTableCell align="right">{row.location}</StyledTableCell>
                <StyledTableCell align="right">{row.workinghours}</StyledTableCell>
                <StyledTableCell align="right">{row.confirmed ? "Approved" : "Not Approved"}
                </StyledTableCell>
                <StyledTableCell align="right">
                <MDialog open={showRemarks} content=
                {
                  <>
                  <div className="form-row">
        <div className="form-group col-md-6">
        <textarea onChange={(e) => setRemarks(e.target.value)}>{remarks}</textarea>
  </div>
        </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                <input type="button"  className="btn btn-success btn-large" value="Submit"  onClick={() => updateRemarks(row.attendanceid, remarks)}/>
                </div>
                <div className="form-group col-md-6">
                <input type="button"  className="btn btn-success btn-large" value="Close" onClick={() => setShowRemarks(false)}/>                
                </div>
                </div>  
              </>      
                } 
                callClose={() => setShowRemarks(false)} />
                <EditIcon onClick={() => {setRemarksData(row.empid, row.remarks, "attendance");setShowRemarks(true) } }></EditIcon>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className=' displayContentRow'>
          <button className='buttonHeader' onClick={() => fnUpdateAttendance()}>Submit</button>
          </div>
  
      </>
  }   
      <div className='title' style={{cursor:'pointer', marginTop:'2%'}} onClick ={()=> {showEmployee(!employees);showAttendance(false)}}>Employee Notifications</div>
  {employees && !attendance && 
  <>
      <TableContainer component={Paper} sx={{backgroundColor: 'grey' }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Empid</StyledTableCell>
              <StyledTableCell align="right">Name</StyledTableCell>
              <StyledTableCell align="right">Work</StyledTableCell>
              <StyledTableCell align="right">Designation</StyledTableCell>
              <StyledTableCell align="right">Location</StyledTableCell>
              <StyledTableCell align="right">Approved</StyledTableCell>
              <StyledTableCell align="right">Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: 'white' }}>
            {employeenotifylist && employeenotifylist.length > 0 && employeenotifylist  .map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.empid}
                </StyledTableCell>
                <StyledTableCell align="right">{row.name}</StyledTableCell>
                <StyledTableCell align="right">{row.work}</StyledTableCell>
                <StyledTableCell align="right">{row.designation}</StyledTableCell>
                <StyledTableCell align="right">{row.location}</StyledTableCell>
                <StyledTableCell align="right">{row.confirmed ? "Approved" : "Not Approved"}
              </StyledTableCell>

                <StyledTableCell align="right">
                <MDialog open={showEmplRemarks} content=
                {
                  <>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                    <textarea onChange={(e) => setRemarks(e.target.value)}>{remarks}</textarea>
                    </div>
                  </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                <input type="button"  className="btn btn-success btn-large" value="Submit"  onClick={() => updateRemarks()}/>
                </div>
                <div className="form-group col-md-6">
                <input type="button"  className="btn btn-success btn-large" value="Close" onClick={() => setShowEmplRemarks(false)}/>                
                </div>
                </div>  
              </>      
                } 
                callClose={() => setShowEmplRemarks(false)} />
                <EditIcon onClick={() => {setRemarksData(row.empid, row.remarks, "employee");setShowEmplRemarks(true) } }></EditIcon>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className=' displayContentRow'>
          <button className='buttonHeader' onClick={() => fnCreateEmployee()}>Submit</button>
          </div>
  
  </>
  }
  
      </div>
    );
  }




