import * as actions from './actions'
  
let initialState = {
  empid: -1,
  location: "",
  Role : 1, //1mean user 2 admin 3 super admin
  Name : "",
  attendanceList : [],
  employeenotifylist:[],
  Employees : [{"EmpId": "12345", "Name" : "test", "Designation" : "Engr1",
                "DOB":"09051988","AadharNo":"12345","Location":"Chennai","Phone":"1234567890"},{"EmpId": "12345", "Name" : "test", "Designation" : "Engr1",
                "DOB":"09051988","AadharNo":"12345","Location":"Chennai","Phone":"1234567890"},{"EmpId": "12345", "Name" : "test", "Designation" : "Engr1",
                "DOB":"09051988","AadharNo":"12345","Location":"Chennai","Phone":"1234567890"}],
  employee: {"empid":"","name":"","designation":"","dob":"","aadhar_no":"","location":"","address":"","phone_no":"","alternate_no":"","role":"1","isdeleted":0,"work":"Full","confirmed":false}
}
  
function appstate(state = initialState, action) {
  console.log("reducer" + action.type)
  switch (action.type) {
    case actions.SET_EMPLOYEES :
              console.log(JSON.stringify(action))

      return {...state, Employees: action.payload}
      case actions.SET_EMPLOYEE :
        console.log(JSON.stringify(action))

return {...state, employee : {...action.payload}}

      case actions.SELECT_EMPLOYEE :
        console.log("seectemployee" + JSON.stringify(action))
return {...state, empid: action.payload}
case actions.EMPLOYEE_ACTION :
  console.log("setemployeeaction" + JSON.stringify(action))
return {...state, empaction: action.payload}
case actions.SET_EMPID :
  console.log("setempid" + JSON.stringify(action))
return {...state, empid: action.payload}
case actions.SET_LOCATION :
  console.log("setemployeloction" + JSON.stringify(action))
return {...state, location: action.payload}
case actions.SET_ROLE :
  console.log("setemployerole" + JSON.stringify(action))
return {...state, role: action.payload}
case actions.SET_NAME :
  console.log("setemployename" + JSON.stringify(action))
return {...state, name: action.payload}
case actions.SET_ATTENDANCE :
  console.log("setemployeattendalist" + JSON.stringify(action))
return {...state, attendanceList: action.payload};
case actions.SET_EMPLOYEENOTIFY :
  console.log("setemployenotifylist" + JSON.stringify(action))
return {...state, employeenotifylist: action.payload}

              default:
            return state
  }
}

export default appstate