import logo from './logo.svg';
import './App.css';
import {useNavigate,Link as RouterLink} from "react-router-dom";
import React, { useState, useEffect } from "react";

function App() {
  const navigate = useNavigate();
  // let login = localStorage.getItem("LoggedIn") == "true" ? "true" : "false";

  // useEffect(() => {
   
  //   // if (window.performance) {
  //   //   if (performance.navigation.type == 1) {
  //   //     //alert( "This page is reloaded" );
  //   //     navigate("/Logout")
  //   //   } else {
  //   //     //alert( "This page is not reloaded");
  //   //   }
  //   // }
  //     if(login == "true"){
  //       if (window.performance) {
  //         if (performance.getEntriesByType("navigation")) {
  //           //alert( "This page is reloaded" );
  //           window.location.href="/"
  //         } else {
  //           //alert( "This page is not reloaded");
  //         }
  //       }
  //     }
    
  //   }, [login]);
    
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
