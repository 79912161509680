import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import {getDataFromApi, displayValue , checkLogin, PostDataToApi} from'./Utils';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Bloodtype, BorderStyle } from '@mui/icons-material';
import MDialog from './MDialog';
import CreateEmployee from './CreateEmployee';
import { API_DOMAIN } from './const';
import { setDayWithOptions } from 'date-fns/fp';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#CCC',
    //color: theme.palette.common.white,
    color:'#0c4c8a',
    fontWeight: 'bold',
    fontSize: 16,

    },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellField = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: '1px solid rgba(224, 224, 224, 1)',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

let headCells = [
  {
    id: 'empid',
    numeric: false,
    disablePadding: true,
    label: 'EmpId',
  },
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'Designation',
    numeric: false,
    disablePadding: false,
    label: 'Designation',
  },
  {
    id: 'Location',
    numeric: false,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'Phone',
    numeric: false,
    disablePadding: false,
    label: 'PhoneNumber',
  },
    
];


function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListEmployee() {
  const Employees = useSelector((state) => state.appstate.Employees)
  const empid = useSelector((state) => state.appstate.empid)
  const role = useSelector((state) => state.appstate.role);

  const dispatch = useDispatch();

  const [filteredEmployees, setFilteredEmployees] = React.useState([]);
  const [order, setOrder] = React.useState('name');
  const [ops, setOps] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [emplid, setEmplid] = React.useState("");
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [ShowEmployee, setShowEmployee] = React.useState(false);
  console.log("employeeength" + Employees.length);
  const navigate = useNavigate();

  useEffect(() => {

  if(role != "1" || role != 1 ){
    let k = headCells.filter(i => i.id == "Edit/Delete" );
    if(k.length == 0){
    headCells.push(
      {
        id: 'Edit/Delete',
        numeric: false,
        disablePadding: false,
        label: 'Edit/Delete',
        alignItems:'center',
        justifyContent:'center'
      });
  }
    let t = headCells.filter(i => i.id == "Advance" );
    if(t.length == 0){
    headCells.push(
    {
      id: 'Advance',
      numeric: false,
      disablePadding: false,
      label: 'Advance',
      alignItems:'center',
      justifyContent:'center'
    });
  }
  }
}, []);

  useEffect(() => {
    checkLogin(navigate);    
    });
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredEmployees.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleView = (event,role, empid, ops) => {
    setShowEmployee(true);
    setOps(ops);
    if(role == "1")
    alert("access denied");
    else{
    console.log("view"+ role)
    dispatch(actions.selectEmployee(empid));
    dispatch(actions.setEmployeeAction("read"));
    }
  }

  const handleDelete = (event,role, empid) => {
    if(window.confirm("are you sure want to delete!") == true) {
    if(role == "1")
    alert("access denied");
    else{
    console.log("view"+ role)
    let obj = { "empid" : empid};
    PostDataToApi(API_DOMAIN + "employee/DeleteEmployee", obj).then((res) => {
      console.log(res.data);
      if (res.data && res.data) {
        navigate("/ListEmployee");
      }
      else {
        alert(JSON.stringify(res.data));
      }
    });
  }
    }
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const fnFilterEmpl = () =>{
    let arr = null;
    if(emplid != -1){
      arr = Employees.filter(i => i.empid === emplid);
      setFilteredEmployees(arr);
    }
  }
  const onAdvance = (empid) => {
    dispatch(actions.selectEmployee(empid));
    if(empid != -1){
      let arr = filteredEmployees.filter(i => i.empid === empid);
      if(arr && arr.length>0 )
        dispatch(actions.setEmployee(arr[0]));  
    }
    navigate("/Advance");
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredEmployees.length) : 0;

  useEffect(() => {    
    getDataFromApi( API_DOMAIN + "employee/list").then((res) =>{
    console.log(res.data);
    dispatch(actions.setEmployees(res.data));
    setFilteredEmployees(res.data);
    console.log("Sss" + JSON.stringify(Employees));
    setOrderBy("empid");
  });
  }, [empid]);

  return (
    <>
    <div className="container">
      <div>     <Header/>
</div>
      <div className='title'>
        Employees List
        </div>
        <form>
        <div className="form-row" style={{marginTop:'3%'}}>
      <div className="form-group col-md-12">
      <label for="addr"><font color="#0c4c8a" >Empid</font></label>
      <input style={{marginLeft:'3%'}} type="text"  value={emplid} onChange={(e) => setEmplid(e.target.value)} onBlur={() => fnFilterEmpl()}  />
      </div>
      </div>
        </form>
</div>
<MDialog open={ShowEmployee} content={<CreateEmployee ops={ops} callClose={() => setShowEmployee(false)}></CreateEmployee>} callClose={() => setShowEmployee(false)} />
<Box sx={{ width: '95%' }}>
      <Paper sx={{ border:'1px', margin: '2%', width: '99%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={filteredEmployees.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(filteredEmployees, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      
                      <StyledTableCellField
                      hover
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                        style={{fontWeight:'bold',cursor:'pointer'
                      }}
                         onClick={(event) => handleView(event, row.Role, row.empid, 'view')}                        
                      >
                        {row.empid}
                      </StyledTableCellField>
                  
                      <StyledTableCellField align="center">{row.name}</StyledTableCellField>
                      <StyledTableCellField align="center">{row.designation}</StyledTableCellField>
                      <StyledTableCellField align="center">{row.location}</StyledTableCellField>
                      <StyledTableCellField align="center">{row.phone_no}</StyledTableCellField>
                      <StyledTableCellField
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                    { (role != "1" || role != 1 ) &&
                      <>
                        <EditIcon onClick={(event) => handleView(event, row.Role, row.empid, 'edit')}                        
></EditIcon>
                        <DeleteIcon onClick={(event) => handleDelete(event, row.Role, row.empid)}                        
></DeleteIcon>
                        </>
                }
                      </StyledTableCellField>  
                      { (role != "1" || role != 1 ) &&
    <StyledTableCellField
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        <EditIcon onClick={() => onAdvance(row.empid)}></EditIcon>
                      </StyledTableCellField>   }                   
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <StyledTableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredEmployees.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <FormControlLabel sx={{margin: '1%'}}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
    </>

  );
}




