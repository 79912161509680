import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import { useEffect } from 'react';
import { PostDataToApi, fileUpload } from './Utils'
import { API_DOMAIN } from './const';
import * as React from 'react';
import {useNavigate } from "react-router-dom";
import { set } from 'date-fns';
import LoginPageBackground from './LoginPageBackground.png'

export default function Login(props) {
  const dispatch = useDispatch();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [Msg, setMsg] = React.useState("");
  const navigate = useNavigate();
  const location = useSelector((state) => state.appstate.location);
  const name = useSelector((state) => state.appstate.name)
  const role = useSelector((state) => state.appstate.role)
  let login = localStorage.getItem("LoggedIn") == "true" ? "true" : "false";
  console.log("login:" + localStorage.getItem("LoggedIn") + login);
  
//   const myStyle={
//     backgroundImage: `url(${LoginPageBackground})` ,
//     height:'100vh',
//     marginTop:'-70px',
//     fontSize:'50px',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
// };

  const fnLogin = () => {
    let obj = { "empid" : userName, "password": password}
    PostDataToApi(API_DOMAIN + "employee/verifyLogin", obj).then((res) =>{
    console.log(res.data);
    if(res.data && typeof res.data == "object" && res.data.length > 0){
      dispatch(actions.setLocation(res.data[0]["location"]));
      //dispatch(actions.setEmpId(res.data[0]["empid"]));
      dispatch(actions.setRole(res.data[0]["role"]));
      dispatch(actions.setName(res.data[0]["name"]));
      localStorage.setItem("LoggedIn", "true");
      //alert("Logged in sucessfully");
      navigate("/ListEmployee");  
    }
    else
      setMsg("username password not matching");
    });
  }

  return (
    // <div style={myStyle}>
          <div className='container'>

        {/* <Header/> */}
        { login == "false" && 
        <>        
        <div className='title'>
        Login       
      </div>
        <div className='subContainer'>
          <div className="form-row">
            <div className="form-group col-md-6">
                <label for="name"><font color="#0c4c8a">Login</font></label>
                <input type="text" className="form-control"  placeholder="Enter Employee Id" required="" value={userName} onChange={e => setUserName(e.target.value)}  />
            </div>
            
            <div  className="form-group col-md-6">
              <label for="adhar"><font color="#0c4c8a">Password:</font></label> 
              <input type="password" className="form-control"  value={password} onChange={e => setPassword(e.target.value)}  placeholder="Enter Password" required=""/>      
            </div>      
          </div>

          <div className="form-row">
      <div className="form-group col-md-6">
      {Msg}
      </div>
      </div>

          <div className="form-row">
      <div className="form-group col-md-6">
      <input type="button"  class="btn btn-success btn-large" value="Login" onClick={() => fnLogin()}/>
      </div>
      </div>          
      
         </div>
         </>
  }
  { login == "true" &&
  <><Header/> 
  <div> welcome {name} from {location}. you are a {role == "1" || role == 1 ? "Regular User" : "Admin"} </div>
  </>
    }
    </div>
  );
}




