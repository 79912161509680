import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import { useEffect } from 'react';
import { PostDataToApi, fileUpload } from './Utils'
import { API_DOMAIN } from './const';
import * as React from 'react';
import {useNavigate } from "react-router-dom";

export default function Logout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
      console.log("logout")
    localStorage.setItem("LoggedIn", "false");
    navigate("/");
  });
  
  return (
    <div className="container">
        <Header/>
        <div>Logged out successfuly
           </div>
    </div>
  );
}




