import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { List, ListItem, makeStyles, Divider} from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import * as React from 'react';
import { PostDataToApi, fileUpload,checkLogin } from './Utils'
import { API_DOMAIN} from './const';
import {useNavigate } from "react-router-dom";
import { useEffect,useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


export default function Payslip() {
  const Employees = useSelector((state) => state.appstate.Employees)
  const dispatch = useDispatch()
  const [month, setMonth] = React.useState("");
  const [year, setYear] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const itemsPerPage = 4;
  const [page, setPage] = React.useState(1);
  const [noOfPages,setNoofPages] = React.useState(1);
  const [paysliplist, setPaysliplist] = React.useState([]);
  const navigate = useNavigate();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  const rowpayslip = (i) => {
    return(
      <>
        <Box sx={{ flexGrow: 1,display:'flex',flexDirection:mobileView?'column':'row' }}>
<Grid container>

  <Grid item xs={8}>
  <div className="form-row">
    
      <div style={{display:'flex',width:'100%', justifyContent:'center',marginLeft:'40%'}} >

          <label for="name" style ={{fontWeight:'bold',fontSize:30,textAlign:'center'}}><font color="#0c4c8a">PaySlip</font></label>                 
      </div>
    </div>
    <div className="form-row">                  
    </div>
    <div className="form-col">
      <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">Empid:</font></label>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.empid}</font></label>
          </div>

          <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">Name:</font></label>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.name}</font></label>
          </div>            

          <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
            <label style={{justifyContent:'flex-start',width:'20%'}} for="adhar"><font color="#0c4c8a">Designation:</font></label> 
            <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.designation}</font></label> 
          </div>

          <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
            <label style={{justifyContent:'flex-start',width:'20%'}} for="adhar"><font color="#0c4c8a">Location:</font></label> 
            <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.location}</font></label> 
          </div>

    </div>
    {/* <div className="form-col"> */}
    <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
        <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">Month:</font></label>
        <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.month}</font></label>
        </div>

        <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
        <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">Year:</font></label>
        <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.year}</font></label>
        </div>            


        <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
        <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">Hours:</font></label>
        <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.workinghours}</font></label>
        </div>            

        <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="adhar"><font color="#0c4c8a">Salary:</font></label> 
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.salary}</font></label> 
        </div>

        <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="adhar"><font color="#0c4c8a">Advance:</font></label> 
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.advance}</font></label> 
        </div>
        <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="adhar"><font color="#0c4c8a">PF:</font></label> 
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.pfadvance}</font></label> 
        </div>

        <div  style={{display:'flex',justifyContent:'space-around',width:'150%'}}>
          <label style={{justifyContent:'flex-start',width:'20%'}} for="adhar"><font color="#0c4c8a">Others:</font></label> 
          <label style={{justifyContent:'flex-start',width:'20%'}} for="name"><font color="#0c4c8a">{i.others}</font></label> 
        </div>

  {/* </div> */}
  </Grid>
  </Grid>
</Box>

  </>
    )
  }
  useEffect(() => {
  checkLogin(navigate);
  });

  //let allMonths = [];
  let allMonths = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const handleChange = (event, value) => {
    console.log(value+ ' value ' + noOfPages )
    setPage(value);
  };

 // for(let x = 1; x<= 12; x++) {
    
     // allMonths.push(monthNames);
  //}

  let allYears = [];
  for(let x = 2022; x<= 2100; x++) {
    allYears.push(x);
  }
  const monthList = () => allMonths.map((x, i) => { 
    console.log("ssdd" + x);
    return(<option value={i+1}>{x}</option>)});
  
    const yearList = () => allYears.map((x) => { 
      return(<option value={x}>{x}</option>)});
    
    const fnChange = (e, key) => {
      if(key == "month")
      setMonth(e.target.value);
      else if(key == "branch")
      setBranch(e.target.value);
      else
      setYear(e.target.value);
    }
      const fnCreatePaySlip = () => {
        let obj = {"month": month, "year":year, "branch": branch};
        PostDataToApi( API_DOMAIN +"employee/payslipList", obj).then((res) =>{
          console.log(res.data);
          res.data= res.data && res.data.filter(i=> i.salary>0)
          setPaysliplist(res.data);
          setNoofPages(Math.ceil(res.data.length / itemsPerPage))

          //dispatch(actions.setEmployees(res.data));
          //console.log("Sss" + JSON.stringify(Employees))
        });
      }
const displayDesktop = () => {
let k=0;
  return (
    <div >
        {/* <Header/> */}
          <div className='title'>
            Payslip
          </div>
          <form className='displayRow' >
            <div className="form-group col-md-2">Branch</div>
            <div className="form-group col-md-2">
              <select  className="form-control" required="" onChange={(e) => fnChange(e, "branch")} >
                <option value="select">Select</option>
                <option value="Pallikaranai">Pallikaranai</option>
                <option value="Medavakkam">Medavakkam</option>
              </select>
            </div>
          
            <div className="form-group col-md-2">Month</div>  
            <div className="form-group col-md-2">
              <select  className="form-control" required="" onChange={(e) => fnChange(e, "month")}>
                <option value="select">select</option>
                {monthList()}
                </select>
            </div>
            <div className="form-group col-md-2">Year</div>  

            <div className="form-group col-md-2">
              <select input="" type="text" className="form-control" required="" onChange={(e) => fnChange(e, "year")}>
              <option value="select">select</option>
              {yearList()}
              </select>
            </div>
            <div className="form-group col-md-2">
              <input type="button"  class="btn btn-success btn-large" onClick={() => fnCreatePaySlip()} value="Submit" />
            </div>
          </form>
      
          {/* <Stack spacing={8}> */}
           <Pagination count={noOfPages} color="primary" page={page} onChange={handleChange}  />

            
          <List dense compoent="span" style={{display:'flex',flexDiretion:'column'}}>
            {console.log(paysliplist.length+' length '+JSON.stringify(paysliplist))}
        {paysliplist
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .slice(0,2)
          .map(i => {
            return rowpayslip(i)
            })
          }
          </List>
          <List dense compoent="span" style={{display:'flex',flexDiretion:'column'}}>
            {console.log(paysliplist.length+' length '+JSON.stringify(paysliplist))}
        {paysliplist
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .slice(2,4)
          .map(i => {
            return rowpayslip(i)
            })
          }
          </List>

          {
            paysliplist.length == 0 && 
            <div>No payslips for this month</div>
          }
    {/* </Stack> */}

    </div>

  );
}

const displayMobile = () => {
  return(
<div >
        {/* <Header/> */}
          <div className='title'>
            Payslip
          </div>
          
          <div className='displayColumn'>
          <div className='displayRowsame'>

            <div style={{marginLeft:'5%',width:'50%'}}>Branch</div>
            <div style={{marginLeft:'5%',width:'50%'}}>
              <select  className="form-control" required="" onChange={(e) => fnChange(e, "branch")} >
                <option value="select">Select</option>
                <option value="Pallikaranai">Pallikaranai</option>
                <option value="Medavakkam">Medavakkam</option>
              </select>
            </div></div>
            <div className='displayRowsame'>

            <div style={{marginLeft:'5%',width:'50%'}}>Month</div>  
            <div style={{marginLeft:'5%',width:'50%'}}>
              <select  className="form-control" required="" onChange={(e) => fnChange(e, "month")}>
                <option value="select">select</option>
                {monthList()}
                </select>
            </div></div>
            <div className='displayRowsame'>
            <div style={{marginLeft:'5%',width:'50%'}}>Year</div>  

            <div style={{marginLeft:'5%',width:'50%'}}>
              <select input="" type="text" className="form-control" required="" onChange={(e) => fnChange(e, "year")}>
              <option value="select">select</option>
              {yearList()}
              </select>
            </div></div>
            <div >
              <input type="button"  class="btn btn-success btn-large" value="Submit" onClick={() => fnCreatePaySlip()}/>
            </div>
          </div>
      
          {/* <Stack spacing={8}> */}
           <Pagination count={noOfPages} color="primary" page={page} onChange={handleChange}  />

            
          <List dense compoent="span" style={{display:'flex',flexDiretion:'column'}}>
            {console.log(paysliplist.length+' length '+JSON.stringify(paysliplist))}
        {paysliplist
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .slice(0,1)
          .map(i => {
            return rowpayslip(i)
            })
          }
          </List>
          <List dense compoent="span" style={{display:'flex',flexDiretion:'column'}}>
            {console.log(paysliplist.length+' length '+JSON.stringify(paysliplist))}
        {paysliplist
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .slice(2,4)
          .map(i => {
            return rowpayslip(i)
            })
          }
          </List>

          {
            paysliplist.length == 0 && 
            <div style={{textAlign:'center'}}>No payslips for this month</div>
          }
    {/* </Stack> */}

    </div>

  );
}
return (
    
  <React.Fragment>
      <Header/>

      {mobileView ? displayMobile() : displayDesktop()}
  </React.Fragment>

);
}
