import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ListEmployee from './ListEmployee';
import CreateEmployee from './CreateEmployee';
import Attendance from './Attendance';
import Payslip from './Payslip';
import Header from './Header';
import Notifications from './Notifications';
import EmplNotifications from './EmplNotifications';
import Advance from './Advance';
import Logout from './Logout';
import Login from './Login';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import combinedreducer from './combinedreducer';
import { useNavigate,  Switch, Route, BrowserRouter as Router, withRouter, Routes } from 'react-router-dom';
import { createStore, combineReducers,applyMiddleware } from 'redux';


let store = createStore(combinedreducer);

ReactDOM.render(
  
  <React.StrictMode>
    {/* <App /> */}
    <Provider store={store}>

    <Router>
      <Routes>
    <Route path="/" element={<Login/>} />
    <Route path="/ListEmployee"  element={<ListEmployee/>} />
    <Route path="/Header"  exact  element={<Header/>} />
    <Route path="/CreateEmployee" exact  element={<CreateEmployee/>} />
    <Route path="/Attendance" exact element={<Attendance/>} />
    <Route path="/Payslip" exact  element={<Payslip/>} />
    <Route path="/Logout" exact  element={<Logout/>} />
    <Route path="/Notifications" exact element={<Notifications/>} />
    <Route path="/Advance" exact element={<Advance/>} />
    <Route path="/EmplNotifications" exact element={<EmplNotifications/>} />

     </Routes>
     </Router>
     </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
