import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import { PostDataToApi, fileUpload, checkLogin } from './Utils'
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useTabsList } from '@mui/material';
import { API_DOMAIN, API_DOMAIN_UPLOADS } from './const';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import DatePicker from '@mui/lab/DatePicker';

export default function CreateEmployee(props) {
  const Employees = useSelector((state) => state.appstate.Employees);
  const employee = useSelector((state) => state.appstate.employee);
  const empaction = useSelector((state) => state.appstate.empaction);
  const empid = useSelector((state) => state.appstate.empid)
  const UPLOAD_ENDPOINT = API_DOMAIN + 'employee/FileUpload';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => state.appstate.role);
  
  useEffect(() => {
    //checkLogin(navigate);
  });

  const [file, setFile] = React.useState(null);
  const [doc, setDoc] = React.useState(null);

  //const [employee, setEmployee] = React.useState({"empid":"","name":"s","designation":"","dob":"","aadhar_no":"","location":"","address":"","phone_no":"","alternate_no":"","role":"1","isdeleted":0,"work":""});
  const fnChange = (val, key) => {
    if (key == "aadhar_no") {
      if (val.length == 13) return false;   //limits to 12 digit entry

    }

    if (key == "phone_no")
      if (val.length == 11) return false;   //limits to 10 digit entry
    if (key == "alternate_no")
      if (val.length == 11) return false;   //limits to 10 digit entry
      
    employee[key] = val;

    //setEmployee(employee);
    dispatch(actions.setEmployee(employee));
  }

  const DOBChange = (newval) => {
    employee["dob"] = newval;
    dispatch(actions.setEmployee(employee));

  }

  const fnAdmin = (e) => {
    if (employee["role"] != "3") {
      employee["role"] = e.target.checked ? "2" : "1";
      dispatch(actions.setEmployee(employee));
    }
  }

  useEffect(() => {
    if (empid != -1) {
      let arr = Employees.filter(i => i.empid === empid);
      //      setEmployee(arr[0]);
      if (arr && arr.length > 0)
        dispatch(actions.setEmployee(arr[0]));
    }
  }, [empid]);

  const fnCreateEmployee = () => {
    let obj = employee;
    //    obj.role = "1";
    //    obj.isdeleted = 0;
    //    obj.confirmed = false;
    if(employee.empid){
      PostDataToApi(API_DOMAIN + "employee/UpdateEmployeeData", obj).then((res) => {
        console.log(res.data);
          props.callClose(false);
          navigate("/ListEmployee");
        });
    }
    else{
      PostDataToApi(API_DOMAIN + "employee/CreateEmployee", obj).then((res) => {
        console.log(res.data);
        if (res.data && res.data[0]["LAST_INSERT_ID()"]) {
          dispatch(actions.selectEmployee(res.data[0]["LAST_INSERT_ID()"]));
          if (file)
            onSubmitFile(null, "photo", res.data[0]["LAST_INSERT_ID()"]);
          if (doc)
            onSubmitFile(null, "doc", res.data[0]["LAST_INSERT_ID()"]);
          alert("Employee added successfuly");
          props.callClose(false);
          navigate("/ListEmployee");
          //newempid = res.data;  
        }
        else {
          alert(JSON.stringify(res.data));
        }
      });
    }
  }

  const onSubmitFile = async (e, type, tempid) => {
    let res = await uploadFile(file, "?empid=" + tempid + "&type=" + type);
    console.log(res.data);
  }
  const onChangeFile = (e, type) => {
    if (type === "photo") {
      setFile(e.target.files[0]);
      console.log(file);
    }
    else {
      setDoc(e.target.files[0]);
      console.log(doc);

    }
  }
  const uploadFile = async (file, qrystr) => {

    const formData = new FormData();

    formData.append('avatar', file)

    return fileUpload(UPLOAD_ENDPOINT + qrystr, formData);
  }

  return (
    <div className="container">
      {/* <Header/> */}
      <div className='title'>
        Employees Details
      </div>
      <div>
        <form >
          <div className="form-row">
            <div className="form-group col-md-6">
              <label for="name"><font color="#0c4c8a">Name</font></label>
              <input type="text" className="form-control" placeholder="Enter Employee Name" required="" value={employee.name} onChange={(e) => fnChange(e.target.value, "name")} />
            </div>

            <div className="form-group col-md-6">
              <label for="adhar"><font color="#0c4c8a">Designation</font></label>
              <input type="text" className="form-control" value={employee.designation} onChange={(e) => fnChange(e.target.value, "designation")} placeholder="Enter Designation" required="" />
            </div>
          </div>


          <div className="form-row">
            <div className="form-group col-md-6">
              <label for="age"><font color="#0c4c8a">D.O.B</font></label>
              <input type="date"   className="form-control" name="dob" id="dob" value={employee.dob} onChange={(e) => fnChange(e.target.value, "dob")} placeholder="Enter DOB" />
            </div>

            <div className="form-group col-md-6">
              <label for="Aadhar"><font color="#0c4c8a">Aadhar No</font></label>
              <input type="number"   className="form-control" name="AadharNo" id="AadharNo" value={employee.aadhar_no} onChange={(e) => fnChange(e.target.value, "aadhar_no")} placeholder="Enter Aadhar No" />
            </div>
          </div>


          <div className="form-row">
            <div className="form-group col-md-6">
              <label for="edq"><font color="#0c4c8a">Branch</font></label>
              <select input="" type="text"   className="form-control" required="" value={employee.location} onChange={(e) => fnChange(e.target.value, "location")}>
                <option value="select">Select</option>
                <option value="Pallikaranai">Pallikaranai</option>
                <option value="Medavakkam">Medavakkam</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label for="edq"><font color="#0c4c8a">Work</font></label>
              <select input=""   type="text" className="form-control" required="" value={employee.work} onChange={(e) => fnChange(e.target.value, "work")}>
                <option value="select">select</option>
                <option value="full">full</option>
                <option value="part">part</option>
              </select>
            </div>
          </div>


          <div className="form-row">
            <div className="form-group col-md-12">
              <label for="addr"><font color="#0c4c8a">Address</font></label>
              <input type="text"   className="form-control" placeholder="Enter Address" value={employee.address} onChange={(e) => fnChange(e.target.value, "address")} />
            </div>


          </div>


          <div className="form-row">
            <div className="form-group col-md-6">
              <label for="mob"><font color="#0c4c8a">Phone No</font></label>
              <input type="tel"   className="form-control" placeholder="Enter Contact Number" value={employee.phone_no} onChange={(e) => fnChange(e.target.value, "phone_no")} />
            </div>

            <div className="form-group col-md-6">
              <label for="fee"><font color="#0c4c8a">Alternate No</font></label>
              <input type="tel"   className="form-control" placeholder="Enter Alternate No" value={employee.alternate_no} onChange={(e) => fnChange(e.target.value, "alternate_no")} />
            </div>

          </div>
          { (role == 3 || role == "3") &&
          <div className="form-row">
            <div className="form-group col-md-6">
              <label for="mob"><font color="#0c4c8a">User Name</font></label>
              <input type="tel"   className="form-control" placeholder="Enter User Name" value={employee.username} onChange={(e) => fnChange(e.target.value, "username")} />
            </div>

            <div className="form-group col-md-6">
              <label for="fee"><font color="#0c4c8a">Password</font></label>
              <input type="tel"   className="form-control" placeholder="Enter Password" value={employee.password} onChange={(e) => fnChange(e.target.value, "password")} />
            </div>

          </div>
          }
          <div className="form-row">

            <div className="form-group col-md-6">
              <label for="pic"><font color="#0c4c8a">Profile Photo</font></label>
              <input   type="file" className='form-control' placeholder="Upload Candidate Photo" onChange={(e) => onChangeFile(e, 'photo')} />
            </div>

            <div className="form-group col-md-6">
              <label for="pic"><font color="#0c4c8a">Document</font></label>
              <input    type="file" className='form-control' placeholder="Upload Candidate Photo" onChange={(e) => onChangeFile(e, 'doc')} />
            </div>
          </div>
<div className="form-row">
            <div className="form-group col-md-6">
              <input type="button" className="btn btn-success btn-large" value="Submit" onClick={() => fnCreateEmployee()} />
            </div>
            <div className="form-group col-md-6">
              <input type="button" className="btn btn-success btn-large" value="Close" onClick={() => props.callClose(false)} />
            </div>
          </div>

        </form>
      </div>
      { /*
        <div className='displayContentRowCenter'>
        <div style={{display:'flex',flexDirection:'column'}}>
            <label className='subDivRowAddr'>Emp Id</label>
          <input className='textboxAddr' value={employee.empid} onChange={(e) => fnChange(e.target.value, "empid") }  ></input>
        </div>
        </div>
        
         <div className=' displayContentRow'>
         <div style={{display:'flex',flexDirection:'column'}}>
            <label className='subDivRow'>Name</label>
          <input className='textbox' value={employee.name} onChange={(e) => fnChange(e.target.value, "name") } ></input>
          </div>
          <div style={{display:'flex',flexDirection:'column'}}>
          <label className='subDivRow'>Designation</label>
          <input className='textbox' value={employee.designation} onChange={(e) => fnChange(e.target.value, "designation") } ></input>
        </div>
        </div>
        <div className=' displayContentRow'>
        <div style={{display:'flex',flexDirection:'column'}}>
            <label className='subDivRow'>D.O.B</label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={1}>
          <DatePicker
          label="dob"
          minDate={new Date('2022-01-01')}
          value={employee.dob}
          onChange={DOBChange}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />       
          </Stack>
          </LocalizationProvider>
          </div>
          <div style={{display:'flex',flexDirection:'column'}}>
          <label className='subDivRow'>Aadhar No</label>
          <input className='textbox' value={employee.aadhar_no} onChange={(e) => fnChange(e.target.value, "aadhar_no")}></input>
        </div>
        </div>
        <div className=' displayContentRow'>
        <div style={{display:'flex',flexDirection:'column'}}>

            <label className='subDivRow'>Location/Branch</label>
                 <select className='textbox' value={employee.location} onChange={(e) => fnChange(e.target.value, "location")}
        // value={this.state.selectValue} 
        // onChange={this.handleChange}   
      >
       <option value="Orange">Pallikaranai</option>
        <option value="Radish">Medavakkam</option>
        <option value="Cherry">Velachery</option>
      </select>   
      </div>
      <div style={{display:'flex',flexDirection:'column'}}>
      <label className='subDivRow'>Work</label>
          <select className='textbox' value={employee.work} onChange={(e) => fnChange(e.target.value, "work")}>
          <option value="Full">Full</option>
        <option value="Part">Part</option>
      </select>
        </div>
        </div>
        <div className='displayContentRowAddr'>
        <div style={{display:'flex',flexDirection:'column'}}>
            <label className='subDivRowAddr'>Address</label>
          <input className='textboxAddr' value={employee.address} onChange={(e) => fnChange(e.target.value, "address")}></input>
        </div>
        </div>
        <div className='displayContentRow'>
        <div style={{display:'flex',flexDirection:'column'}}>
            <label className='subDivRow'>Phone no</label>
          <input className='textbox' value={employee.phone_no} onChange={(e) => fnChange(e.target.value, "phone_no")}></input>
          </div>
          <div style={{display:'flex',flexDirection:'column'}}>
           <label className='subDivRow'>Alternate no</label>
           <input className='textbox' value={employee.alternate_no} onChange={(e) => fnChange(e.target.value, "alternate_no")}></input>
        </div>
        </div>

        <div className=' displayContentRow'>
        <div style={{display:'flex',flexDirection:'column'}}>
            <label className='subDivRow'>Photo</label>
            <input type="file" className='subDivFile' onChange={(e) =>  onChangeFile(e, 'photo') } />
            </div>
            <div style={{display:'flex',flexDirection:'column'}}>
          <label className='subDivRow'>Document</label>
          <input type="file" className='subDivFile' onChange={(e) =>  onChangeFile(e, 'doc') } />
        </div>
        </div>
        <div className='displayContentRow'>
            <label className='subDivRow'>Photo</label>
            <img className='img' src={API_DOMAIN_UPLOADS + "uploads/" + employee.photo}></img>
          <label className='subDivRow'>Document</label>
          <img className='img' src={API_DOMAIN_UPLOADS + "uploads/" + employee.document}></img>
        </div>
        <div className='displayContentRow'>  
        <FormControlLabel control={
        <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={employee.role === "2"} onChange={fnAdmin}   />
        } label="Admin" />
        </div>
        {empaction != "read" &&
        <div className='displayContentRow'>
          <button className='buttonHeader' onClick={() => fnCreateEmployee()}>Submit</button>
          <button className='buttonHeader' onClick={() => props.callClose(false)}>Close</button>
          </div>}
          */ }
    </div>
  );
}




