import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate,Link as RouterLink} from "react-router-dom";
import MDialog from './MDialog';
import React, { useState, useEffect } from "react";
import CreateEmployee from './CreateEmployee';
import * as ReactBootStrap from 'react-bootstrap';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  Drawer,
  Link,
} from "@material-ui/core";

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Notifications from '@mui/icons-material/Notifications';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
const headersData = [
  {
    label: "List",
    href: "/ListEmployee",
  },
  {
    label: "Create Employee ",
    href: "/CreateEmployee",
  },
  {
    label: "Attendance",
    href: "/Attendance",
  },
  {
    label: "Payslip",
    href: "/Payslip",
  },
  {
    label: "Advance",
    href: "/Advance",
  },
  {
    label: "Notifications",
    href: "/Notifications",
  },
  {
    label: "Log Out",
    href: "/logout",
  },
];
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#fff",
    paddingRight: "79px",
    paddingLeft: "118px",

    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#000",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));
export default function Header() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();

  const Employees = useSelector((state) => state.appstate.Employees);
  const role = useSelector((state) => state.appstate.role);

  const dispatch = useDispatch()

  const navigate = useNavigate();
  const [ShowEmployee, setShowEmployee] = React.useState(false);
  const name = useSelector((state) => state.appstate.name)

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  let login = localStorage.getItem("LoggedIn") == "true" ? "true" : "false";

  useEffect(() => {
    window.addEventListener("beforeunload", function(event) { 
      console.log("The page is redirecting")           
      movenxt("Logout")
 });
     console.log("ssTTTT" + sessionStorage.getItem("LoggedIn3"))
      // if(sessionStorage.getItem("LoggedIn3") == "true"){
      //   if (window.performance) {
      //     if (performance.navigation.type == 1) {
      //       //alert( "This page is reloaded" );
      //       //window.location.href="/"
      //       sessionStorage.setItem("LoggedIn3", "false");
      //       movenxt("Logout")
      //     } else {
      //       //alert( "This page is not reloaded");
      //     }
      //   }
      // }
      // else if(window.location.href.indexOf("Logout") === -1)
      // sessionStorage.setItem("LoggedIn3", "true");
    }, [null]);
 
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const femmecubatorLogo = (
    <Typography variant="h6" component="h1" className={logo}>
      Home
    </Typography>
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    setShowEmployee(true);
    dispatch(actions.setEmployeeAction("create"));
    // if(role == "1")
    // alert("access denied");
    // console.log("view"+ role)
  }
  function stringToColor(name) {
    let hash = 0;
    let i;
     if(name)
     {
    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substring(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
}
  
  function stringAvatar(name) {
    if(name){

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
    };
  }
  }
  const displayDesktop = () => {
    return (
<Box sx={{ display: 'flex',cursor:'pointer', alignItems:'center', textAlign: 'center',justifyContent:'space-around',color:'#0c4c8a',fontWeight:'bold' }}>
        <Typography onClick={()=>movenxt("Home")} sx={{ minWidth: 100 }}>Home</Typography>
        <Typography onClick={()=>movenxt("ListEmployee")} sx={{ minWidth: 100 }}>List</Typography>
        <Typography onClick={()=>movenxt("Create Employee")} sx={{ minWidth: 100 }}>Create Employee</Typography>
        <Typography onClick={()=>movenxt("Attendance")} sx={{ minWidth: 100 }}>Attendance</Typography>
        <Typography onClick={()=>movenxt("Payslip")} sx={{ minWidth: 100 }}>Payslip</Typography>
        { (role != "1" || role != 1 ) &&
        <Typography onClick={()=>movenxt("Advance")} sx={{ minWidth: 100 }}>Advance</Typography>
  }
        <MDialog open={ShowEmployee}  content={<CreateEmployee callClose={() => setShowEmployee(false)}></CreateEmployee>} callClose={() => setShowEmployee(false)} />
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar {...stringAvatar(name)} />          
        </IconButton>
        </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       <MenuItem>
          <ListItemIcon>
            <PersonIcon fontSize="small" color='blue'/>
          </ListItemIcon>
          {name}
        </MenuItem>
        {(role !== "1" && role !== 1) && 
        <MenuItem onClick={()=>movenxt("Notifications")}>
          <ListItemIcon>
              <Notifications fontSize="small" />
          </ListItemIcon>
          Notifications        
        </MenuItem>
        }
        { (role == "1" || role == 1) && <MenuItem onClick={()=>movenxt("EmplNotifications")}>
          <ListItemIcon>
              <Notifications fontSize="small" />
          </ListItemIcon>
          UserNotifications
        </MenuItem>}
        <MenuItem onClick={()=>movenxt("Logout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        </Menu>
       </Box>
  );
}

function movenxt(screen)
{
    if(screen == 'Home')
    {
        navigate("/")

    }
    if(screen == "ListEmployee")
    {
      navigate("/ListEmployee")
    }
    else if(screen == 'Create Employee')
    {
        // navigate("/CreateEmployee")
        dispatch(actions.selectEmployee(0));
        dispatch(actions.setEmployee([]));
        handleView()
     }
    else if(screen == 'Attendance')
    {
        navigate("/Attendance")

    }
    else if(screen == 'Payslip')
    {
        navigate("/Payslip")

    }
    else if(screen == 'Notifications')
    {
        navigate("/Notifications")

    }
    else if(screen == 'EmplNotifications')
    {
        navigate("/EmplNotifications")

    }    
    else if(screen == 'Advance')
    {
        navigate("/Advance")

    }
    else if(screen == 'Logout')
    {
      //alert("you are going tologout");
        navigate("/Logout")
    }

}
//   return (
//     <div className="container">
//           {/* <div className="Headercontainer"> */}

//         <div className='displayRow'>
//           <button className='buttonHeader' onClick={()=>movenxt("Home")}>Home</button>
//           <button className='buttonHeader' onClick={()=>movenxt("ListEmployee")}>List</button>
//           <button className='buttonHeader'onClick={()=>movenxt("Create Employee")}>Create Employee</button>
//           <button className='buttonHeader' onClick={()=>movenxt("Attendance")}>Attendance</button>
//           <button className='buttonHeader' onClick={()=>movenxt("Payslip")}>Payslip</button>
//           <ReactBootStrap.Dropdown>
//                     <ReactBootStrap.Dropdown.Toggle variant="success" id="dropdown-basic">
//                     <Avatar {...stringAvatar(name)} />
//                     </ReactBootStrap.Dropdown.Toggle>

//                     <ReactBootStrap.Dropdown.Menu>
//                     { (role == "2" || role == "3") &&

//                       <ReactBootStrap.Dropdown.Item href="#/action-1" onClick={()=>movenxt("Notifications")}>Noifications</ReactBootStrap.Dropdown.Item>
//   }
//                       <ReactBootStrap.Dropdown.Item href="#/action-2" onClick={()=>movenxt("Logout")}>Logout</ReactBootStrap.Dropdown.Item>
//                     </ReactBootStrap.Dropdown.Menu>
//                   </ReactBootStrap.Dropdown>
//           <Avatar {...stringAvatar('Tim Neutkens')} />

//           { (role == "2" || role == "3") &&
//           <button className='buttonHeader' onClick={()=>movenxt("Notifications")}>Notifications</button>
//           }
//           <MDialog open={ShowEmployee}  content={<CreateEmployee callClose={() => setShowEmployee(false)}></CreateEmployee>} callClose={() => setShowEmployee(false)} />
//           <button className='buttonHeader' onClick={()=>movenxt("Logout")}>Logout</button>          
//         </div>
//         {/* </div> */}
//     </div>
//   );
// }

  return (
    
    <React.Fragment>
          <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
      {/* <div style={{display:"flex",justifyContent:'flex-end',marginBottom:'3%',cursor:'pointer'}}> */}

       {/* <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar {...stringAvatar(name)} />          
        </IconButton>
        </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       
        {(role !== "1" && role !== 1) && 
        <MenuItem onClick={()=>movenxt("Notifications")}>
          <ListItemIcon>
              <Notifications fontSize="small" />
          </ListItemIcon>
          Notifications        
        </MenuItem>
        }
        { (role == "1" || role == 1) && <MenuItem onClick={()=>movenxt("EmplNotifications")}>
          <ListItemIcon>
              <Notifications fontSize="small" />
          </ListItemIcon>
          UserNotifications
        </MenuItem>}
        <MenuItem onClick={()=>movenxt("Logout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        </Menu> */}
        {/* </div> */}
      {/* <Box sx={{ display: 'flex',cursor:'pointer', alignItems:'center', textAlign: 'center',justifyContent:'space-around' }}>
        <Typography onClick={()=>movenxt("Home")} sx={{ minWidth: 100 }}>Home</Typography>
        <Typography onClick={()=>movenxt("ListEmployee")} sx={{ minWidth: 100 }}>List</Typography>
        <Typography onClick={()=>movenxt("Create Employee")} sx={{ minWidth: 100 }}>Create Employee</Typography>
        <Typography onClick={()=>movenxt("Attendance")} sx={{ minWidth: 100 }}>Attendance</Typography>
        <Typography onClick={()=>movenxt("Payslip")} sx={{ minWidth: 100 }}>Payslip</Typography>
        { (role == "2" || role == 2 || role == 3 ||  role == "3") &&
        <Typography onClick={()=>movenxt("Advance")} sx={{ minWidth: 100 }}>Advance</Typography>
  }
        <MDialog open={ShowEmployee}  content={<CreateEmployee callClose={() => setShowEmployee(false)}></CreateEmployee>} callClose={() => setShowEmployee(false)} />
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar {...stringAvatar(name)} />          
        </IconButton>
        </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       <MenuItem>
          <ListItemIcon>
            <PersonIcon fontSize="small" color='blue'/>
          </ListItemIcon>
          {name}
        </MenuItem>
        {(role !== "1" && role !== 1) && 
        <MenuItem onClick={()=>movenxt("Notifications")}>
          <ListItemIcon>
              <Notifications fontSize="small" />
          </ListItemIcon>
          Notifications        
        </MenuItem>
        }
        { (role == "1" || role == 1) && <MenuItem onClick={()=>movenxt("EmplNotifications")}>
          <ListItemIcon>
              <Notifications fontSize="small" />
          </ListItemIcon>
          UserNotifications
        </MenuItem>}
        <MenuItem onClick={()=>movenxt("Logout")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        </Menu>
       </Box> */}
    </React.Fragment>
  );
}




