/*
 * action types
 */

export const SET_EMPLOYEES = 'SET_EMPLOYEES'

export const SELECT_EMPLOYEE = 'SELECT_EMPLOYEE'

export const SET_EMPLOYEE = 'SET_EMPLOYEE'

export const EMPLOYEE_ACTION = 'EMPLOYEE_ACTION'

export const SET_EMPID = 'SET_EMPID'

export const SET_LOCATION = 'SET_LOCATION'

export const SET_ROLE = 'SET_ROLE'

export const SET_NAME = 'SET_NAME'

export const SET_ATTENDANCE = 'SET_ATTENDANCE';

export const SET_EMPLOYEENOTIFY = 'SET_EMPLOYEENOTIFY';
/*
 * other constants
 */

export const Const_Values = {
}

/*
 * action creators
 */

export function setEmployees(employees) {
  return {
     type: SET_EMPLOYEES,
     payload: employees
   }
}


export function selectEmployee(empid) {
  return {
     type: SELECT_EMPLOYEE,
     payload: empid
   }
}

export function setEmployee(employee) {
  return {
     type: SET_EMPLOYEE,
     payload: employee
   }
}

export function setEmpId(employee) {
  return {
     type: SET_EMPID,
     payload: employee
   }
}

export function setLocation(employee) {
  return {
     type: SET_LOCATION,
     payload: employee
   }
}


export function setEmployeeAction(action) {
  return {
     type: EMPLOYEE_ACTION,
     payload: action
   }
}

export function setRole(action) {
  return {
     type: SET_ROLE,
     payload: action
   }
}

export function setName(action) {
  return {
     type: SET_NAME,
     payload: action
   }
}

export function setAttendanceList(action){
  return {
    type: SET_ATTENDANCE,
    payload: action
  }
}
export function setEmployeeNotifyList(action){
  return {
    type: SET_EMPLOYEENOTIFY,
    payload: action
  }
}
