import logo from './logo.svg';
import './App.css';
import { connect } from 'react-redux'
import * as actions from './actions';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header.js'
import { useEffect } from 'react';
import { API_DOMAIN } from './const';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PostDataToApi,checkLogin } from './Utils'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import MDialog from './MDialog';
import * as React from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Notifications(props) {
  const attendanceList = useSelector((state) => state.appstate.attendanceList)
  const employeenotifylist = useSelector((state) => state.appstate.employeenotifylist);
  const employee = useSelector((state) => state.appstate.employee);
  const empaction = useSelector((state) => state.appstate.empaction);
  const location = useSelector((state) => state.appstate.location);
  const empid = useSelector((state) => state.appstate.empid);
  const role = useSelector((state) => state.appstate.role);
  const dispatch = useDispatch();
  let month = (new Date()).getMonth();
  let year = (new Date()).getFullYear();
  const [showRemarks, setShowRemarks] = React.useState(false);
  const [showEmplRemarks, setShowEmplRemarks] = React.useState(false);
  const [attendance, showAttendance] = React.useState(false);
  const [employees, showEmployee] = React.useState(false);
  const [remarks, setRemarks] = React.useState("");
  const [remarksid, setRemarksid] = React.useState("");
  const [remarkstype, setRemarkstype] = React.useState("");
  const [adminRole, setRole] = React.useState("User");

  const navigate = useNavigate();
  useEffect(() => {
    checkLogin(navigate);
    });

  const fnChange = (val, id, colname)=> {
    let t  = employeenotifylist;
    console.log("test")
    t.map(i => {
      if(i.empid == id){
        if(colname == "basepay")
          i.basepayscale = val;
        else if(colname == "weekendscale")
          i.weekendscale = val;
        else
          i.payscale = val; 
      }
    });
    dispatch(actions.setEmployeeNotifyList([]));
    dispatch(actions.setEmployeeNotifyList(t));
  }
  
  const setRemarksData = (id, val, type) => {
    setRemarksid(id);
    setRemarks(val);
    setRemarkstype(type);
  }

  const updateRemarks = () => {
    if(remarkstype == "employee"){
    let t  = employeenotifylist;
    t.map(i => {
      if(i.empid == remarksid){
        i.remarks = remarks;
      }
    });
    dispatch(actions.setEmployeeNotifyList([]));
    dispatch(actions.setEmployeeNotifyList(t));
    setShowEmplRemarks(false);
    }
    else{
      let t  = attendanceList;
      t.map(i => {
        if(i.attendanceid == remarksid){
          i.remarks = remarks;
        }
      });
      dispatch(actions.setAttendanceList([]));
      dispatch(actions.setAttendanceList(t));
      setShowRemarks(false);  
    }
  }

  const fnAttendanceList = (attendanceid,val, colname)=> {
    let t  = attendanceList;
    if(colname === "pfdays"){
      t.map(i => {
        if(i.attendanceid == attendanceid){
          i.pfdays = val;
          i.hrconfirmed = 1;        
     
        }
      });
    }
    else if(colname === "deduction"){
      t.map(i => {
        if(i.attendanceid == attendanceid){
          i.deduction = val;
        }
      });
    }
    else if(colname === "others"){
      t.map(i => {
        if(i.attendanceid == attendanceid){
          i.others = val;
        }
      });
    }
    else{
      t.map(i => {
        if(i.attendanceid == attendanceid){
          i.confirmed = i.confirmed == 1 ? 0 : 1;        
        }
      });
    }
    dispatch(actions.setAttendanceList([]));
    dispatch(actions.setAttendanceList(t));
  }

    const updateConfirmedinEmpl = (empid) =>{
      let t  = employeenotifylist;
      t.map(i => {
        if(i.empid == empid){
          i.confirmed = i.confirmed == 1 ? 0 : 1;        
        }
      });
      dispatch(actions.setEmployeeNotifyList([]));
      dispatch(actions.setEmployeeNotifyList(t));
    }
  

    const updateAdmin = (empid,val) =>{
      setRole(val)
      console.log(val + "role" +empid)
     
      let t  = employeenotifylist;
      t.map(i => {
        if(i.empid == empid){
          //i.role = i.role == 1 ? 2 : 1;   
          if(val=='User')
          i.role=1;
          if(val=='Admin')
          i.role=2;
          if(val=='HR')
          i.role=4;
        }
      });
      dispatch(actions.setEmployeeNotifyList([]));
      dispatch(actions.setEmployeeNotifyList(t));
    }


  useEffect(() => {
    LoadData();
  }, []);


  const LoadData = () => {
  
    let obj = {"location" : location, "month" : month, "year" : year, "role" : role};
    PostDataToApi( API_DOMAIN + "employee/getNotificationsList", obj).then((res) =>{
      console.log(res.data);
      if(typeof res.data == "object" ){
      
        dispatch(actions.setAttendanceList(res.data.filter(i => i.type == "attendance")));
        dispatch(actions.setEmployeeNotifyList(res.data.filter(i => i.type == "employee")));
      
      }
    });
    
  }
  const fnUpdateAttendance = () => {
    let obj = { "role" : role, "ListEmpl" : attendanceList};
    if(obj ){
      PostDataToApi(API_DOMAIN + "employee/updateAttendance", obj).then((res) =>{
      console.log(res.data);
      LoadData();        
      });
    }

  }
  const fnCreateEmployee = () => {

    
    if(employeenotifylist && employeenotifylist.length > 0 ){
      PostDataToApi(API_DOMAIN + "employee/updateEmployee", employeenotifylist).then((res) =>{
      console.log(res.data);
      LoadData();        
        
      });
    }
    
  }

  return (
    <div className="container" >
        <Header/>
      <div className='title' style={{cursor:'pointer'}}  onClick ={()=> {showAttendance(!attendance);showEmployee(false)}}>Attendance Notification</div>
      
     {attendance && !employees &&
     <>

<TableContainer component={Paper} sx={{backgroundColor: 'red' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Empid</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Work</StyledTableCell>
            <StyledTableCell align="right">Designation</StyledTableCell>
            <StyledTableCell align="right">Location</StyledTableCell>
            <StyledTableCell align="right">No of days</StyledTableCell>
            <StyledTableCell align="right">No of Hours</StyledTableCell>
            <StyledTableCell align="right">PF Days</StyledTableCell>
            <StyledTableCell align="right">Deduction</StyledTableCell>
            <StyledTableCell align="right">Others</StyledTableCell>
            {role !='4' && role != 4 && <><StyledTableCell align="right">Approved</StyledTableCell>
            <StyledTableCell align="right">Remarks{attendanceList.length}</StyledTableCell></>}
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor: 'white' }}>
          {attendanceList && attendanceList.length > 0 && attendanceList.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.empid}
              </StyledTableCell>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.work}</StyledTableCell>
              <StyledTableCell align="right">{row.designation}</StyledTableCell>
              <StyledTableCell align="right">{row.location}</StyledTableCell>
              <StyledTableCell align="right">{row.work == "full" ? row.workinghours : row.weekendhours}</StyledTableCell>
              <StyledTableCell align="right">{row.work == "part" ? row.workinghours:""}</StyledTableCell>
              <StyledTableCell align="right">
                { row.work == "full" ?
              <TextField 
                    id="standard-error-helper-text"
                    // label="PF Days"
                    value={row.pfdays}
                    variant="standard" onChange={(e) => fnAttendanceList(row.attendanceid, e.target.value, "pfdays")}></TextField>
              : row.pfdays }
                    </StyledTableCell>
              <StyledTableCell align="right">
              <TextField 
                    id="standard-error-helper-text"
                    value={row.deduction}
                    variant="standard" onChange={(e) => fnAttendanceList(row.attendanceid, e.target.value, "deduction")}></TextField>
                    </StyledTableCell>
                    <StyledTableCell align="right">
              <TextField 
                    id="standard-error-helper-text"
                    value={row.others}
                    variant="standard" onChange={(e) => fnAttendanceList(row.attendanceid, e.target.value, "others")}></TextField>
                    </StyledTableCell>
             {role !='4'&& role!=4 && <StyledTableCell align="right">
              <Checkbox
                          color="primary"
                          checked={row.confirmed != "0" || row.confirmed != 0} 
                          onChange={() => fnAttendanceList(row.attendanceid)}
                        />
            
              </StyledTableCell>}
              {role !='4'&& employee.role!= 4 && <>
              <StyledTableCell align="right">
              <MDialog open={showRemarks} content=
              {
                <>
                <div className="form-row">
      <div className="form-group col-md-6">
      <textarea onChange={(e) => setRemarks(e.target.value)}>{remarks}</textarea>
</div>
      </div>
              <div className="form-row">
              <div className="form-group col-md-6">
              <input type="button"  className="btn btn-success btn-large" value="Submit"  onClick={() => updateRemarks(row.attendanceid, remarks)}/>
              </div>
              <div className="form-group col-md-6">
              <input type="button"  className="btn btn-success btn-large" value="Close" onClick={() => setShowRemarks(false)}/>                
              </div>
              </div>  
            </>      
              } 
              callClose={() => setShowRemarks(false)} />
            <EditIcon onClick={() => {setRemarksData(row.empid, row.remarks, "attendance");setShowRemarks(true) } }></EditIcon>
              </StyledTableCell></>}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    <div className="form-row">
      <div className="form-group col-md-12">      
        </div>
      </div>

        <div className="form-row">
      <div className="form-group col-md-6">
      <input type="button"  class="btn btn-success btn-large" value="Submit" onClick={() => fnUpdateAttendance()}/>
      </div>
      
      </div>          
 
    </>
}   
    <div className='title' style={{cursor:'pointer', marginTop:'2%'}} onClick ={()=> {showEmployee(!employees);showAttendance(false)}}>Employee Notifications</div>
{employees && !attendance && 
<>
    <TableContainer component={Paper} sx={{backgroundColor: 'grey' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
        {employeenotifylist && employeenotifylist.length > 0 && employeenotifylist.map((row, i) => 
          { 
            if(i === 0)
            return( 
          <TableRow>
            <StyledTableCell>Empid</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Work</StyledTableCell>
            <StyledTableCell align="right">Designation</StyledTableCell>
            <StyledTableCell align="right">Location</StyledTableCell>
            <StyledTableCell align="right">Role</StyledTableCell>
            <StyledTableCell align="right">Salary(days)</StyledTableCell>
            <StyledTableCell align="right">Salary(hours)</StyledTableCell>
            <StyledTableCell align="right">BasePay</StyledTableCell>
            <StyledTableCell align="right">Approved</StyledTableCell>
            <StyledTableCell align="right">Remarks</StyledTableCell>
          </TableRow>
            )})}
        </TableHead>
        <TableBody sx={{backgroundColor: 'white' }}>
          {employeenotifylist && employeenotifylist.length > 0 && employeenotifylist  .map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.empid}
              </StyledTableCell>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.work}</StyledTableCell>
              <StyledTableCell align="right">{row.designation}</StyledTableCell>
              <StyledTableCell align="right">{row.location}</StyledTableCell>
              <StyledTableCell align="right">
                    {/* <Checkbox
                          color="primary"
                          checked={row.role != "1" || row.role != 1} 
                          onChange={() => updateAdmin(row.empid)}
                        /> */}
                        <FormLabel id="demo-radio-buttons-group-label">Role</FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="User"
                                name="radio-buttons-group"
                                value={adminRole}
                                
                                onChange={(e) =>  (row.empid,e.target.value)}
                            
                              >
                                <FormControlLabel value="User" control={<Radio checked={row.role == "1" || row.role == 1} />} label="User" />

                                <FormControlLabel value="Admin" control={<Radio checked={row.role == "2" || row.role == 2} />} label="Admin" />
                                <FormControlLabel value="HR" control={<Radio checked={row.role == "4" || row.role == 4} />} label="HR" />
                              </RadioGroup>

              </StyledTableCell>
              <StyledTableCell align="right">
              <TextField 
          id="standard-error-helper-text"
          label="Payscale"
          value={row.payscale}
          variant="standard" onChange={(e) => fnChange(e.target.value, row.empid)}></TextField>
              </StyledTableCell>
              <StyledTableCell align="right">
              {row.work =="part" ?   
              <TextField 
          id="standard-error-helper-text"
          label="Payscale"
          value={row.weekendscale}
          variant="standard" onChange={(e) => fnChange(e.target.value, row.empid, "weekendscale")}></TextField>
              : "" }</StyledTableCell>
              {row.work =="full" ?   <StyledTableCell align="right">              
              <TextField 
                    id="standard-error-helper-text"
                    label="BasePay"
                    value={row.basepayscale}
                    variant="standard" onChange={(e) => fnChange(e.target.value, row.empid, 'basepay')}></TextField>
              </StyledTableCell> : <div></div>}
              <StyledTableCell align="right">
                    <Checkbox
                          color="primary"
                          checked={row.confirmed != "0" || row.confirmed != 0} 
                          onChange={() => updateConfirmedinEmpl(row.empid)}
                        />
              </StyledTableCell>
              <StyledTableCell align="right">
              <MDialog open={showEmplRemarks} content=
              {
                <>
                <div className="form-row">
                  <div className="form-group col-md-6">
                  <textarea onChange={(e) => setRemarks(e.target.value)}>{remarks}</textarea>
                  </div>
                </div>
              <div className="form-row">
              <div className="form-group col-md-6">
              <input type="button"  className="btn btn-success btn-large" value="Submit"  onClick={() => updateRemarks()}/>
              </div>
              <div className="form-group col-md-6">
              <input type="button"  className="btn btn-success btn-large" value="Close" onClick={() => setShowEmplRemarks(false)}/>                
              </div>
              </div>  
            </>      
              } 
              callClose={() => setShowEmplRemarks(false)} />
              <EditIcon onClick={() => {setRemarksData(row.empid, row.remarks, "employee");setShowEmplRemarks(true) } }></EditIcon>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    <div className="form-row">
      <div className="form-group col-md-12">      
        </div>
      </div>

        <div className="form-row">
      <div className="form-group col-md-6">
      <input type="button"  class="btn btn-success btn-large" value="Submit" onClick={() => fnCreateEmployee()}/>
      </div>
      
      </div>
</>
}
{/*
<div className='subContainer'>
        <div className=' displayContentRowCenter'>
            <div className='subDivRowCenter'>Emp Id</div>
          <input className='textboxCenter' value={employee.empid} onChange={(e) => fnChange(e.target.value, "empid")} onBlur={handleView}  ></input>
        </div>
        <div className=' displayContentRow'>
            <div className='subDivRow'>Name</div> 
          <input className='textbox' value={employee.name} onChange={(e) => fnChange(e.target.value, "name")}></input>
          <div className='subDivRow'>Work</div>
          <input className='textbox' value={employee.work} onChange={(e) => fnChange(e.target.value, "work")} ></input>
        </div>
        <div className=' displayContentRow'>
            <div className='subDivRow'>Designation</div>
          <input className='textbox' value={employee.designation} onChange={(e) => fnChange(e.target.value, "designation")}  ></input>
          <div className='subDivRow'>Location</div>
          <input className='textbox' value={employee.location} onChange={(e) => fnChange(e.target.value, "location")} ></input>
        </div>
        <div className=' displayContentRowAddr'>
            <div className='subDivRowDays'>No of Days/Hours</div>
                
          <input className='textboxDays' value={employee.workinghours} onChange={(e) => fnChange(e.target.value, "workinghours", "")}  ></input>
        </div>
        
        <div className=' displayContentRow'>
        <button className='buttonHeader' onClick={() => fnCreateEmployee()}>Submit</button>
          <button className='buttonHeader' onClick={() => props.callClose(false)}>Close</button>
        </div>
        </div>
*/}

     {/* <div className=' displayContentRow'>
        <button className='buttonHeader' onClick={() => fnCreateEmployee()}>Submit</button>
        </div> */}

    </div>
  );
}




